import React from 'react';
import Head from 'next/head';
import PropTypes from 'prop-types';

const ArticleSchema = ({ seoMetadata }) => {
  console.log(seoMetadata);

  return (
    <Head>
      <script type='application/ld+json'>
        {JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'Article',
          name: seoMetadata?.pageTitle,
          description: seoMetadata?.description,
          keywords: seoMetadata?.keywords,
          url: seoMetadata?.canonicalUrl,
          image: seoMetadata?.ogImage
        })}
      </script>
    </Head>
  );
};

ArticleSchema.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  datePublished: PropTypes.string.isRequired,
  image: PropTypes.string
};

export default ArticleSchema;
